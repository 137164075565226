import {
  HIDDEN_COLUMNS,
  MANDATORY_COLUMNS,
  OPT_COLUMNS,
  TABLES_LIST,
} from '../../constants/Tables/TableColumnsList'
import { UPDATE_SELECTED_COLUMNS } from '../../constants/Tables/Common'

const initState = {}
TABLES_LIST.forEach((tableName) => {
  const mandatoryColumns = MANDATORY_COLUMNS[tableName].map((obj) => obj.value)
  initState[tableName] = {
    mandatoryColumns: mandatoryColumns,
    optColumns: OPT_COLUMNS[tableName],
    hiddenColumns: HIDDEN_COLUMNS[tableName],
    allColumns: [...MANDATORY_COLUMNS[tableName], ...OPT_COLUMNS[tableName]],
    selectedColumns: mandatoryColumns,
    isFirstRun: true,
  }
})

const tableColumns = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_COLUMNS: {
      let updatedState = { ...state }
      updatedState[action.tableName] = action.updatedTableColObj
      return { ...updatedState }
    }
    default:
      return state
  }
}

export default tableColumns
