import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant'
import {
  TOGGLE_COLLAPSED_NAV,
  SIDE_NAV_STYLE_CHANGE,
  CHANGE_LOCALE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  IS_MOBILE_WINDOW_CHANGE,
} from '../constants/Theme'
import store from '../store'

function getWindowPadding(navType, isMobileWindow, navCollapsed) {
  const isNavTop = navType === NAV_TYPE_TOP
  if (isNavTop || isMobileWindow) {
    return 'windowPaddingLeft_0'
  }
  return navCollapsed
    ? `windowPaddingLeft_${SIDE_NAV_COLLAPSED_WIDTH}`
    : `windowPaddingLeft_${SIDE_NAV_WIDTH}`
}

export function toggleCollapsedNav(navCollapsed) {
  let { navType, isMobileWindow } = store.getState().theme
  let windowPaddingLeft = getWindowPadding(navType, isMobileWindow, navCollapsed)
  return {
    type: TOGGLE_COLLAPSED_NAV,
    data: { navCollapsed, windowPaddingLeft },
  }
}

export function onNavStyleChange(sideNavTheme) {
  return {
    type: SIDE_NAV_STYLE_CHANGE,
    sideNavTheme,
  }
}

export function onLocaleChange(locale) {
  return {
    type: CHANGE_LOCALE,
    locale,
  }
}

export function onNavTypeChange(navType) {
  let { navCollapsed, isMobileWindow } = store.getState().theme
  let windowPaddingLeft = getWindowPadding(navType, isMobileWindow, navCollapsed)
  return {
    type: NAV_TYPE_CHANGE,
    data: { navType, windowPaddingLeft },
  }
}

export function onTopNavColorChange(topNavColor) {
  return {
    type: TOP_NAV_COLOR_CHANGE,
    topNavColor,
  }
}

export function onHeaderNavColorChange(headerNavColor) {
  return {
    type: HEADER_NAV_COLOR_CHANGE,
    headerNavColor,
  }
}

export function onMobileNavToggle(mobileNav) {
  return {
    type: TOGGLE_MOBILE_NAV,
    mobileNav,
  }
}

export function updateWindowSizesOnResize(isMobileWindow) {
  let { navCollapsed, navType } = store.getState().theme
  let windowPaddingLeft = getWindowPadding(navType, isMobileWindow, navCollapsed)
  return {
    type: IS_MOBILE_WINDOW_CHANGE,
    data: { isMobileWindow, windowPaddingLeft },
  }
}
