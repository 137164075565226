const CATEGORY = {
  EKB_PROFILES: '1',
}

const TYPE = {
  AUTOMATION: '1',
  MANUAL: '2',
  IMPORTED: '3',
}

//EKB Component NewDesign Modal
const DESIGN_STATUS = {
  DRAFT: 'Draft',
  COMPLETE: 'Complete',
}

const DIRECTION_DICTIONARY = {
  bottom: 1,
  top: 0,
  middle: 0.5,
  left: 0,
  right: 1,
}

//EKB Editor
const ORIENTATION = 'orientation'
const PORTRAIT = 'portrait'
const LANDSCAPE = 'landscape'
const LAYOUT_NAME_MAX_SIZE = 50

//EKB Component Shortcuts
const ADD_KEY_RIGHT = 'right'
const ADD_KEY_LEFT = 'left'
const ADD_ROW_ABOVE = 'rowAbove'
const ADD_ROW_BELOW = 'rowBelow'
const DELETE_AND_SCALE_ROW = 'deleteAndScaleRow'
const JUST_DELETE_ROW = 'justDelete'
const LAYOUT_TAB_HEIGHT = 27
const MIN_KEYS_IN_ROW = 1
const MAX_KEYS_IN_ROW = 10
const ZOOM_DEFAULT = 1.0
const ZOOM_IN = 2.0

//EKB Component Key
const DEFAULT_FONT_SIZE = 16
const DEFAULT_SECONDARY_FONT_SIZE = 8
const DEFAULT_FONT_COLOR = '#FFFFFF'
const DEFAULT_FONT_STYLE = 'normal'
const DEFAULT_KEYBOARD_BACKGROUND_COLOR = '#005A78'
const DEFAULT_KEY_BUTTON_BACKGROUND_COLOR = '#0077a0'
const DEFAULT_HAPTICFEEDBACK = false
const DEFAULT_HAPTICFEEDBACKDURATION = 1
const ZOOM_LEVEL_DEFAULT = 1.0
const ZOOM_LEVEL_UP = 2.0
const MIN_ROWS_IN_LAYOUT = 1
const MAX_ROWS_IN_LAYOUT = 5
const SIZE = 'size'
const DEFAULT_POSITION = 'anchored'
const DEFAULT_DIRECTION = 'bottom-middle'
const DEFAULT_PRESET_FONT_COLOR = '#000000'
const DEFAULT_SCANNER_FONT_SIZE = 80
const DEFAULT_VOICE_FONT_SIZE = 68
const DEFAULT_STYLE = {
  style: DEFAULT_FONT_STYLE,
  size: DEFAULT_FONT_SIZE,
  color: DEFAULT_FONT_COLOR,
}

//EKB Component KeyAction
const NO_ACTION = 'NO_ACTION'
const CANNED_TEXT = 'CANNED_TEXT'
const CUSTOM_LAYOUT = 'CUSTOM_LAYOUT'
const KEY_CAP = 'KEY_CAP'
const KEY = 'KEY'
const MACROS = 'MACROS'
const SWITCH_123 = 'SWITCH_123'
const SWITCH_SYMBOL = 'SWITCH_SYMBOL' // Switch-#*/
const SWITCH_ABC = 'SWITCH_ABC'
const SWITCH_SCAN = 'SWITCH_SCAN'
const UC = 'UC'
const SCANTRIGGER = 'SCANTRIGGER'
const DELAY = 'DELAY'

const NO_ACTION_OPTIONS = [
  NO_ACTION,
  SWITCH_SYMBOL,
  SWITCH_SCAN,
  SWITCH_ABC,
  SWITCH_123,
  SCANTRIGGER,
]

const NO_ACTION_VALUES = {
  SWITCH_SYMBOL: 'switch-#*/',
  SWITCH_SCAN: 'switch-scan',
  SWITCH_ABC: 'switch-abc',
  SWITCH_123: 'switch-123',
  SCANTRIGGER: 'scantrigger',
  NO_ACTION: null
}

const KEYCAP_PREFIX = 'keyincaps-'
const KEY_PREFIX = 'key-'
const UC_PREFIX = 'uc-'
const CUSTOM_LAYOUT_PREFIX = 'switch-'
const DELAY_PREFIX = 'delay-'
const ICON_CONTENT_PREFIX = '@ekb-'
const PRIMARY = 'primary'
const SECONDARY = 'secondary'
const PRESS = 'press'
const LONG_PRESS = 'longPress'
const UP = 'up'
const DOWN = 'down'

const ACTION_TYPE = {
  ICON: 'icon',
  SPECIAL: 'special',
  TEXT: 'text',
}

const ICONS = {
  ADD_REACTION: ICON_CONTENT_PREFIX + 'addReaction',
  ARROW_DOWN: ICON_CONTENT_PREFIX + 'arrowDown',
  ARROW_LEFT: ICON_CONTENT_PREFIX + 'arrowLeft',
  ARROW_RIGHT: ICON_CONTENT_PREFIX + 'arrowRight',
  ARROW_UP: ICON_CONTENT_PREFIX + 'arrowUp',
  BACKSPACE: ICON_CONTENT_PREFIX + 'backspace',
  BARCODE: ICON_CONTENT_PREFIX + 'barcode',
  BROWSER: ICON_CONTENT_PREFIX + 'browser',
  ENTER: ICON_CONTENT_PREFIX + 'enter',
  TAB: ICON_CONTENT_PREFIX + 'tab',
}

const DEFAULT_ICON_COLOR = '#646A78'

// Sizing
const SPACING = {
  narrow: {
    keyGap: 2,
    layoutGap: 2,
  },
  standard: {
    keyGap: 4,
    layoutGap: 4,
  },
  wide: {
    keyGap: 6,
    layoutGap: 8,
  },
}

const NARROW = 'narrow'
const STANDARD = 'standard'
const WIDE = 'wide'
const MANUAL_SIZING = 'manual-sizing'
const FIT_TO_SCREEN = 'fit-to-screen'

// Keyboard
const DEFAULT_LAYOUT_HEIGHT_PERCENT = 0.2
const DEFAULT_ROW_HEIGHT_PERCENT = 0.1
const MAX_NUMBER_LAYOUTS = 6

// Voice and Scanner layouts presets

const PRESET_LAYOUT_HEIGHT_PERCENT = 0.5
const PRESET_LAYOUT_BACKGROUND_COLOR = '#ffd200'
const SCANNER = ICON_CONTENT_PREFIX + 'scanner'
const VOICE = ICON_CONTENT_PREFIX + 'voice'
const PRESET_LAYOUT_TAB_FONT_SIZE = 16
const ACTION_DEFAULT = { type: NO_ACTION, value: null }
const PRESET_LAYOUTS = [SCANNER, VOICE]

const ENTERPRISE_KEYBOARD_BUILDER_ROUTES = {
  HOME: `/app/enterpriseKeyboardBuilder`,
  EDITOR: `enterpriseKeyboardBuilder/editor`,
}

const MYAPP_ROUTES = {
  MYAPP: '/app/myApps'
}

const MINIMUM_KEY_WIDTH = 5
const APPLY_TO_ALL_KEYS = 'APPLY_TO_ALL_KEYS'
const APPLY_TO_ALL_KEYS_IN_LAYOUT = 'APPLY_TO_ALL_KEYS_IN_LAYOUT'
const APPLY_TO_ALL_LAYOUTS = 'APPLY_TO_ALL_LAYOUTS'

const KEYS = 'KEYS'
const LAYOUTS = 'LAYOUTS'

module.exports = {
  ACTION_TYPE,
  ADD_KEY_LEFT,
  ADD_KEY_RIGHT,
  ADD_ROW_ABOVE,
  ADD_ROW_BELOW,
  CANNED_TEXT,
  CATEGORY,
  CUSTOM_LAYOUT,
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_STYLE,
  DEFAULT_ICON_COLOR,
  DEFAULT_KEYBOARD_BACKGROUND_COLOR,
  DEFAULT_KEY_BUTTON_BACKGROUND_COLOR,
  DEFAULT_LAYOUT_HEIGHT_PERCENT,
  DEFAULT_ROW_HEIGHT_PERCENT,
  DELETE_AND_SCALE_ROW,
  DESIGN_STATUS,
  DOWN,
  FIT_TO_SCREEN,
  ICONS,
  JUST_DELETE_ROW,
  KEY,
  KEYS,
  KEY_CAP,
  LANDSCAPE,
  LAYOUT_NAME_MAX_SIZE,
  LAYOUT_TAB_HEIGHT,
  LAYOUTS,
  LONG_PRESS,
  MACROS,
  MANUAL_SIZING,
  MAX_KEYS_IN_ROW,
  MAX_NUMBER_LAYOUTS,
  MAX_ROWS_IN_LAYOUT,
  MIN_KEYS_IN_ROW,
  MIN_ROWS_IN_LAYOUT,
  NARROW,
  NO_ACTION,
  ORIENTATION,
  PORTRAIT,
  PRESS,
  PRESET_LAYOUT_BACKGROUND_COLOR,
  PRESET_LAYOUT_HEIGHT_PERCENT,
  PRESET_LAYOUT_TAB_FONT_SIZE,
  PRIMARY,
  SCANNER,
  SECONDARY,
  SIZE,
  SPACING,
  STANDARD,
  SWITCH_123,
  SWITCH_ABC,
  SWITCH_SCAN,
  SWITCH_SYMBOL,
  TYPE,
  UC,
  KEYCAP_PREFIX,
  KEY_PREFIX,
  UC_PREFIX,
  UP,
  VOICE,
  WIDE,
  ZOOM_DEFAULT,
  ZOOM_IN,
  ZOOM_LEVEL_DEFAULT,
  ZOOM_LEVEL_UP,
  ACTION_DEFAULT,
  ENTERPRISE_KEYBOARD_BUILDER_ROUTES,
  PRESET_LAYOUTS,
  DEFAULT_POSITION,
  DEFAULT_DIRECTION,
  DEFAULT_SECONDARY_FONT_SIZE,
  DEFAULT_PRESET_FONT_COLOR,
  DEFAULT_SCANNER_FONT_SIZE,
  DEFAULT_VOICE_FONT_SIZE,
  DIRECTION_DICTIONARY,
  SCANTRIGGER,
  MINIMUM_KEY_WIDTH,
  DEFAULT_STYLE,
  APPLY_TO_ALL_KEYS,
  APPLY_TO_ALL_KEYS_IN_LAYOUT,
  APPLY_TO_ALL_LAYOUTS,
  DEFAULT_HAPTICFEEDBACK,
  DEFAULT_HAPTICFEEDBACKDURATION,
  NO_ACTION_OPTIONS,
  NO_ACTION_VALUES,
  CUSTOM_LAYOUT_PREFIX,
  ICON_CONTENT_PREFIX,
  MYAPP_ROUTES,
  DELAY,
  DELAY_PREFIX
}
