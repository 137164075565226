import {
  UPDATE_DEVICE_COUNTS,
  UPDATE_DEVICE_COUNTS_SUCCESS,
  UPDATE_DEVICE_COUNTS_FAILURE,
} from 'redux/constants/DeviceCounts'

const initialState = {
  deviceCounts: {
    activeDevices: 0,
    deviceCount: 0,
    devicesWithThirdPartyDO: 0,
  },
  hasNoZdmAsDo: true,
  lastFetched: null,
  loading: true,
  error: null,
}

const checkHasNoZdmAsDo = (deviceCounts) =>
{if(deviceCounts.devicesWithThirdPartyDO===0 && deviceCounts.deviceCount===0){
  return false
}
 return deviceCounts.devicesWithThirdPartyDO >= deviceCounts.deviceCount
}
const deviceCounts = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DEVICE_COUNTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case UPDATE_DEVICE_COUNTS_SUCCESS:
      return {
        ...state,
        deviceCounts: action.deviceCounts,
        hasNoZdmAsDo: checkHasNoZdmAsDo(action.deviceCounts),
        lastFetched: action.lastFetched,
        loading: false,
        error: null,
      }
    case UPDATE_DEVICE_COUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default deviceCounts
