import { all } from 'redux-saga/effects'
import Auth from './Auth'
import deviceCountsSaga from './DeviceCounts'
import {
  fetchTableColumnListSaga,
  updateTableColumnListSaga,
} from './TableConfiguration/TableColumnListConfigs'

export default function* rootSaga(getState) {
  yield all([Auth(), deviceCountsSaga(), fetchTableColumnListSaga(), updateTableColumnListSaga()])
}
