import { MAX_LENGTH } from 'constants/Constant'

const getAndroidVersion = (obj) => {  
  if (obj.osVersion) {    
    obj.formattedOsVersion = `Android ${obj.osVersion}`
  }
  return obj
}

const getAlphaNumericValueWithSingleSpace = (
  value = '',
  maxLength = MAX_LENGTH.DEVICE_AUTO_NAMING
) => {
  value = value.replace(/[^a-z0-9-_\s]/gi, '').substring(0, maxLength)
  let valueLength = value.length
  let trimedValueLength = value.trim().length
  if (trimedValueLength === 0) {
    return ''
  } else if (valueLength > trimedValueLength + 1) {
    return value.trim() + ' '
  } else {
    return value
  }
}

const helixDevice = {
  model: 'WS50',
}
export { getAndroidVersion, helixDevice, getAlphaNumericValueWithSingleSpace }
