import {
  getFirestore,
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  setDoc,
  collectionGroup,
  deleteDoc,
  onSnapshot,
  getCountFromServer,
} from 'firebase/firestore'
import { db } from 'auth/FirebaseAuth'

//get single document
function getDocument(colPath, documentId) {
  let query = doc(db, colPath, documentId)
  return getDoc(query)
}

// get doc by using document path
function getDocumentUsingDocPath(path) {
  let query = doc(db, path)
  return getDoc(query)
}

//get multiple documents

function getMultipleDocument(collectionPath, conditions = []) {
  let qry = collection(db, collectionPath)
  return getDocs(query(qry, ...conditions))
}

//get total count

function getTotalCountFromDB(collectionPath, queryArr = []) {
  const collectionRef = collection(db, collectionPath)
  return getCountFromServer(query(collectionRef, ...queryArr))
}

//get document using collection group

function getDocumentCollectionGroup(collectionName, conditions = []) {
  let qry = collectionGroup(db, collectionName)
  return getDocs(query(qry, ...conditions))
}

//update document
function setDocument(collectionPath, documentId, data, isMerge) {
  const documentRef = doc(getFirestore(), collectionPath, documentId)
  if (isMerge) {
    return setDoc(documentRef, data, { merge: true })
  } else {
    return setDoc(documentRef, data)
  }
}

//delete document
function deleteDocument(collectionPath, documentId) {
  return deleteDoc(doc(db, collectionPath, documentId))
}

export {
  getDocument,
  setDocument,
  getMultipleDocument,
  getDocumentCollectionGroup,
  deleteDocument,
  getDocumentUsingDocPath,
  getTotalCountFromDB
}
