import React, { useEffect, useContext } from 'react'
import { collectDeveloperData } from './firebase/cloudFunction/index'
import { AuthContext } from 'auth/AuthProvider'
import { getTenantId } from 'auth/FirebaseAuth'

function ErrorFallback({ error, resetErrorBoundary }) {
  const context = useContext(AuthContext)
  const tenantId = getTenantId(context?.currentUser)

  useEffect(() => {
    console.log('ErrorFallback ' + tenantId)
    console.log('Inside ErrorFallback, reload the UI')
    collectDeveloperData(error, tenantId).finally(() => {
      window.location.reload()
    })
  })

  let style = {
    root: {
      display: 'none',
    },
  }

  return (
    <div role="alert" style={style.root}>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default ErrorFallback
