import React, { useContext, useEffect } from 'react'
import { signOut } from 'redux/actions/Auth'
import { useDispatch } from 'react-redux'
import { SESSION_TIMEOUT } from 'constants/Constant'
import { AuthContext } from 'auth/AuthProvider'
import { getLoginType, getTenantId } from 'auth/FirebaseAuth'
import { useIdleTimer } from 'react-idle-timer'

const IdleTimerContainer = ({ children }) => {
  const context = useContext(AuthContext)
  const tenantId = getTenantId(context?.currentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    const lastActiveAt = localStorage.getItem('lastActiveAt')
    if (lastActiveAt && Date.now() - lastActiveAt > SESSION_TIMEOUT && tenantId) {
      dispatch(signOut(getLoginType(context?.currentUser)))
    }
  }, [])

  const onIdle = () => {
    if (tenantId) dispatch(signOut(getLoginType(context?.currentUser)))
  }

  const onAction = () => {
    if (tenantId) localStorage.setItem('lastActiveAt', Date.now())
  }

  const idleTimer = useIdleTimer({
    onIdle,
    onAction,
    throttle: 5000,
    timeout: SESSION_TIMEOUT,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    name: 'idle-timer',
    crossTab: true,
  })

  return <>{children}</>
}

export default IdleTimerContainer
