import {
  DEFAULT_ROW_HEIGHT_PERCENT,
  LAYOUT_TAB_HEIGHT,
  PRESET_LAYOUT_HEIGHT_PERCENT,
} from 'constants/EnterpriseKeyboardBuilderConstant'

export const isNewHeightBigger = (layouts, layoutCurrentIndex, rows) => {
  const orderHelperLayouts = [...layouts]

  const selectedLayout = layouts[layoutCurrentIndex]
  const higherLayout = orderHelperLayouts.reduce((prev, current) =>
    prev.properties.rows > current.properties.rows ? prev : current
  )
  let higherLayoutRows = higherLayout.properties.rows

  const filteredLayout = layouts.filter((v) => v.properties.rows === higherLayoutRows)
  higherLayout === selectedLayout && filteredLayout.length === 1 && (higherLayoutRows -= 1)

  return higherLayoutRows <= rows
}

export const returnHighestLayout = (layouts) => {
  const orderHelperLayouts = [...layouts]
  const higherLayout = orderHelperLayouts.sort((a, b) => b.properties.rows - a.properties.rows)[0]

  return higherLayout
}

export const getRowGap = (currentLayout, layouts) => {
  return currentLayout.properties.rows === 1 &&
    isNewHeightBigger(layouts, currentLayout, currentLayout.properties.rows)
    ? 0
    : currentLayout.properties.keyGap
}

export const getLayoutHeight = (rows, screenHeight, numberLayouts, keyGap, layoutGap) => {
  const layoutHeight = numberLayouts > 1 ? LAYOUT_TAB_HEIGHT : 0
  const keyGapTotal = keyGap * rows
  return (
    rows * screenHeight * DEFAULT_ROW_HEIGHT_PERCENT + layoutHeight + keyGapTotal + layoutGap * 2
  )
}

export const getPresetLayoutHeight = (screenHeight) => {
  return screenHeight * PRESET_LAYOUT_HEIGHT_PERCENT
}
