//import app from "../authentication/firebaseStart";
import axios from 'axios'
import firebaseConfig from 'configs/FirebaseConfig'
import { getAuth } from 'firebase/auth'
const getToken = async () => {
  if (getAuth() && getAuth().currentUser) {
    return (await getAuth().currentUser.getIdTokenResult()).token
  } else {
    return null
  }
}

const instance = axios.create({
  baseURL: `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/`,
  //baseURL: `http://localhost:5001/${firebaseConfig.projectId}/us-central1/`,//localhost
})

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
   
    const token = await getToken()
    const currentUser = getAuth().currentUser
    const tenantId = currentUser?(currentUser?.claims?.identity?currentUser?.claims?.identity:currentUser?.claims?.tenantId):null;
    console.log("Inside Request Interceptors", currentUser,tenantId);
    // const tenantId =  currentUser?.claims?.identity?currentUser?.claims?.identity:currentUser.uid;
    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`,
        // tenantId: userClaims.identity?userClaims.identity:userClaims.tenantId,
        tenantId: tenantId,
        'Content-Type': 'application/json',
      }
    }
    // let auth = true;
    // if(config.method == "post" && config.data.hasOwnProperty("auth") && config.data.auth == false)
    //   auth = false
    // if(config.method == "get" && config.hasOwnProperty("params") && config.params.hasOwnProperty("auth") && config.params.auth == false)
    //   auth = false

    // if(auth){
    //   const token = await getToken();
    //   config.headers = {
    //     'authorization': `Bearer ${token}`,
    //   }
    // }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default instance
