import React, { useContext, createContext, useRef } from 'react'
import AppLocale from 'lang'
import { extractValueByDot } from 'utils/extractValuesByDot'

const LocaleContext = createContext()



export const useTranslation = (localePrefix) => {
  const context = useContext(LocaleContext)
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider')
  }

  const translationRef = useRef(localePrefix ? extractValueByDot(localePrefix, context) : context)

  const t = (key, { global } = {}) => {
    if (!key) return ''

    const translation = global ? context : translationRef.current

    return extractValueByDot(key, translation)
  }
  return { t }
}

export const TranslationProvider = ({ children, lang = 'en' }) => {
  const translations = AppLocale[lang].messages

  return <LocaleContext.Provider value={translations}>{children}</LocaleContext.Provider>
}
